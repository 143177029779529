import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera'
import { Filesystem } from '@capacitor/filesystem'
import { Microphone } from '@mozartec/capacitor-microphone';

const requestCameraPermission = async () => {
    const camera = await Camera.requestPermissions()
    console.log(camera)
    return camera.camera === 'granted'
}

const requestMicrophonePermission = async () => {
    const micro = await Microphone.requestPermissions();
    return micro.microphone === 'granted'
}

const requestFileSystemPermission = async () => {
    const filesystem = await Filesystem.requestPermissions()
    return filesystem.publicStorage  === 'granted'
}

const requestPermissions = async (devices: any, platform: any) => {
    let permissionGranted = true
    if(devices.camera) {
        const permission = await requestCameraPermission();
        if(permission == false) permissionGranted = false
    }
    if(devices.microphone){
        const permission = await requestMicrophonePermission();
        if(permission == false) permissionGranted = false
    } 
    if(devices.screen && platform == 'android'){
        const permission = await requestFileSystemPermission();
        if(permission == false) permissionGranted = false
    } 

    return permissionGranted
}

async function getUserCameraData() {
  
    try {
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            allowEditing: false,
            source:CameraSource.Camera,
            direction:CameraDirection.Front,
            quality: 90
        });
  
        return {
            error: false,
            cameraSrc:photo.webPath,
            cameraDeviceId: 0,
            cameraDeviceName: 'Front Camera'
        }
    } catch (error) {
      return {
        error: true,
        errorDescription: error
      }
    }
}
/*
async function simulateMicrophoneAccess() {
  
    try {  
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;
        let microVolume = 0
        
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
  
        const simulateSoundDetection = () => {
          for (let i = 0; i < bufferLength; i++) {
            dataArray[i] = Math.floor(Math.random() * 255);
          }
          const averageVolume = dataArray.reduce((a, b) => a + b) / bufferLength;
          vm.microVolume = (averageVolume / 255) * 500;
        };
  
        // Simulate sound detection every 200ms
        setInterval(simulateSoundDetection, 200);
  
        // Simulate obtaining microphone device ID (Capacitor doesn't support real-time mic device IDs)
        const deviceInfo = await Device.getInfo();
        vm.devices.micro.id = deviceInfo.uuid;  // Simulated device ID
        console.log('Simulated Microphone ID:', vm.devices.micro.id);
  
        // Simulate enumerating audio devices (there is typically only one microphone on mobile devices)
        const devices = await navigator.mediaDevices.enumerateDevices();
        
        devices.forEach(function(device) {
          if (device.kind === "audioinput") {
            if (device.deviceId === vm.devices.micro.id) {
              vm.devices.micro.name = device.label || "Mobile Microphone";
              console.log('Microphone name:', vm.devices.micro.name);
            }
          }
        });
  
        vm.testErrors.micro = false;
        vm.error = false;
      } else {
        throw new Error('Microphone permission denied');
      }
    } catch (error) {
      console.error('Microphone access error:', error);
      vm.error = true;
      vm.testErrors.micro = true;
      vm.showError(vm.$t('wat_focus_connectivity_error_micro'));  // Handle error accordingly
    }
  }
*/
export {
    requestPermissions,
    requestCameraPermission,
    requestMicrophonePermission,
    requestFileSystemPermission,
    getUserCameraData
}