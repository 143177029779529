
import { defineComponent } from 'vue'
import { IonButtons } from '@ionic/vue'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'TestExecutionHeader',
    components: { IonButtons },
    props: { testTitle: String },
    setup() {
        const router: any = useRouter()
        const goTo = (url: any) => {
            router.push({ name: url, replace: true })
        }
        return {
            goTo,
        }
    },
})
